<template>
  <div id="home" class="home">
    <a-row>
      <a-col :span="24">
        <div style="height: 680px;"><img src="@/assets/img/header_image_1.png" style="width: 100%;height: 100%;">
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="desc">
    <a-row style="height: 100%;">
      <a-col :span="24">
        <a-tabs v-model:activeKey="tab" centered :tabBarGutter="160" class="tab">
          <a-tab-pane key="1" tab="公司简介" >
            <div class="tab-item">
              <div class="tab-item-left">
                <p style="font-size: 14px; font-weight: 400;margin-top: 60px;">
                  上海忆伯科技（300468）成立于2016年，是专精特新、高新技术及上海股权托管交易中心科创板挂牌企业。作为技术方案驱动型公司，主要通过公共数据抓取、分析、中后台数据应用创新手段，依托网格可视化的技术应用方案耕耘于智慧警务、智慧城市及航海航天板块。同时，通过多年的经营管理及技术累积收获知识产权和资质共三十余项，收获荣誉包括：智慧城市联合实验室、公共数据应用创新中心、上海市信息安全行业协会理事单位、第一届上海市无人机安全管理协会会员单位等。
                </p>
                <p style="font-size: 14px; font-weight: 400;">
                  忆伯科技自主研发的ADS-B、AIS系统，和相配套的无人载具自动避碰方案广泛应用于各个航空航天及海事单位，主要包括：中船重工、中国航天、航天科工、自然资源部第一海洋研究所、中国科学院海洋研究所，中电科，中国海事局，西安飞机设计研究所等。
                </p>
                <p style="font-size: 14px; font-weight: 400;">
                  忆伯科技以“简单，务实，高效，结果导向”作为公司价值观，以“技术驱动，业务支撑，行业沉淀”为公司思想理念，以“快速响应，联勤联动”为公司服务准则，为“十四五规划”的落实与发展工作添砖加瓦。
                </p>
              </div>
              <div class="tab-item-right"><img src="@/assets/img/desc.jpg" alt=""></div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="公司历史" force-render>
            <div class="tab-item">

              <a-carousel arrows :dots=false class="swiper">
                <template #prevArrow>
                  <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                    <left-circle-outlined/>
                  </div>
                </template>
                <template #nextArrow>
                  <div class="custom-slick-arrow" style="right: 10px">
                    <right-circle-outlined/>
                  </div>
                </template>

                <div class="history-center" v-for="(item, index) in historys" :key="item">
                  <div class="year_before">
                    <p>{{ index > 0 ? historys[index - 1].date : "" }}</p>
                  </div>
                  <div class="history">
                    <div class="history-title">{{ item.date }}</div>

                    <div class="history-detail"><pre style="white-space: pre;overflow: unset ">{{ item.des }}</pre></div>
                  </div>

                  <div class="year_after">
                    <p>{{ index < historys.length - 1 ? historys[index + 1].date : "" }}</p>
                  </div>
                </div>
              </a-carousel>
            </div>
            <div><img src="@/assets/time-line.png" alt=""
                      style="width:  100%;position: relative;bottom: 250px;z-index: 9999;"></div>
          </a-tab-pane>
          <a-tab-pane key="3" tab="荣誉资质">
            <div class="tab-item3">
              <div class="row">
                <div class="card">
                  <img src="@/assets/img/card/cert2.jpg" alt="">
                  <p>信息技术服务管理体系认证证书</p>
                </div>
                <div class="card">
                  <img src="@/assets/img/card/cert1.jpg" alt="">
                  <p>信息安全管理体系认证证书</p>
                </div>
                <div class="card">
                  <img src="@/assets/img/card/cert3.jpg" alt="">
                  <p>质量管理体系认证证书</p>
                </div>
              </div>
              <div class="row">
                <div class="card">
                  <img src="@/assets/img/card/cert4.jpg" alt="">
                  <p>发明专利证书</p>
                </div>
                <div class="card">
                  <img src="@/assets/img/card/cert5.jpg" alt="">
                  <p>实用新型专利证书 </p>
                </div>
                <div class="card">
                  <img src="@/assets/img/card/cert6.jpg" alt="">
                  <p>外观设计专利证书</p>
                </div>
              </div>
              <div class="row">
                <div class="card">
                  <img src="@/assets/img/software/software1.jpg" alt="">
                </div>
                <div class="card">
                  <img src="@/assets/img/software/software2.jpg" alt="">
                </div>
                <div class="card">
                  <img src="@/assets/img/software/software3.jpg" alt="">
                </div>
                <div class="card">
                  <img src="@/assets/img/software/software4.jpg" alt="">
                </div>
                <div class="card">
                  <img src="@/assets/img/software/software5.jpg" alt="">
                </div>
              </div>

              <div class="card-foot">计算机软件著作权登记证书25项</div>
            </div>
            <div class="tab-item-3">
              <div class="row flex_wrap">
                <div class="card">
                  <img src="@/assets/img/card/zjtx.jpg" alt="">
                  <p>上海市"专精特新"中小企业</p>
                </div>
                <div class="card">
                  <img src="@/assets/img/card/cert9.jpg" alt="">
                  <p>上海无人机安全管理协会</p>
                </div>
                <div class="card">
                  <img src="@/assets/img/card/cert7.jpg" alt="">
                  <p>上海市信息安全行业协会</p>
                </div>
                <div class="card">
                  <img src="@/assets/img/card/cert8.jpg" alt="">
                  <p>上海市数据安全与隐私计算机专委会</p>
                </div>

              </div>

            </div>
          </a-tab-pane>
        </a-tabs>
      </a-col>
    </a-row>

  </div>
</template>

<script>
import {defineComponent, ref, onUpdated, onMounted, getCurrentInstance} from 'vue';
import {LeftCircleOutlined, RightCircleOutlined} from '@ant-design/icons-vue';
import {useRoute} from 'vue-router'

export default defineComponent({
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
  },
  name: "About",
  data() {
    return {
      tab: "3",
      historys: [
        {
          date: "2016",
          des: "上海忆伯科技股份有限公司成立作为一家技术方案驱动型公司，确定以智慧警务、智慧城市及航海航天为深耕板块"
        },
        {
          date: "2019",
          des: "承接某直辖市非机动车智慧化管控项目"
        },
        {
          date: "2020",
          des: "忆伯科技自行研发的ADS-B以及AIS系统已经广泛应用于各个航空航天及海事单位。\n" +
              "上海市杨行镇科创企业家联盟Y30初始成员，上海市信息安全行业协会理事单位，\n" +
              "上海市无人机安全管理协会会员，上海市数据安全与隐私计算专委会第一届会员单位。\n" +
              "2021年年底在上海股权托管交易中心成功转板上股交科技创新板，代码为300468"
        },
        {
          date: "2021",
          des: "为某省级单位构建的智慧勤务（警务）全场景系统解决方案正式上线、\n" +
              "参与某直辖市智慧交通试点工程、承接某市警务巡逻无人机自动避障项目"
        },
        {
          date: "2022",
          des: "AIS和ADS-B军用研发项目、AIS海域应用、5G通讯和北斗系统、ADS-B空域应用系统、\n" +
              "星载AIS以及ADS-B载荷等已经广泛交付运用中。获得创新型中小企业，专精特新中小企业资质。"
        },
        {
          date: "2023",
          des: "1.自研某星载模块配合“复旦一号”总星调试通过。\n" +
              "2.与上海海事大学合作，为更多即将步入社会的学生提供实习基体，助力校企联动，产学研合作。\n" +
              "3.正式步入无人机远程识别（运行识别）的研发，为我们低空经济，低空安全保驾护航。"
        }
      ],
    }
  },
  created() {
    let that = this
    const route = useRoute();
    if (route.query.tab) {
      that.tab = route.query.tab
    }
  },
  watch: {

    $route: {
      handler: function (route) {
        const query = route.query
        let that = this
        if (query) {

          that.tab = query.tab
        }
      }
    }
  },
  methods: {

  },
})
</script>

<style>
.home {
  height: 680px;
  background-color: rgba(104, 110, 130, 1);
  width: 100%;
  min-width: 1280px;
}
</style>
<style scoped>
.desc {
    /* height: 990px; */
    padding: 0 300px;
    min-width: 1280px;
    width: 100%;
    background-color: rgba(28, 31, 43, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-image: url(@/assets/product/bg2.png);
}

.ant-tabs :deep(.ant-tabs-nav .ant-tabs-tab) {
  color: #000;
}

.ant-tabs :deep(.ant-tabs-nav .ant-tabs-tab-btn) {
  background-image: url(@/assets/desc2.png);
  color: #fff;
  background-color: #1c1f2b;
  width: 160px;
  height: 42px;
  text-align: center;
  line-height: 42px;
}

.ant-tabs :deep(.ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: #000;
  background-image: url(@/assets/desc.png);
  width: 160px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  background-size: cover;
}

.ant-tabs-top :deep(.ant-tabs-nav::before,
    .ant-tabs-bottom>.ant-tabs-nav::before) {
  border-bottom: none;
}

.ant-tabs :deep(.ant-tabs-ink-bar) {
  background: none;
}

.tab-item {
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1300px;
  height: 480px;
}

.tab-item .tab-item-left,
.tab-item-right {
  width: 50%;
  height: 100%;
}

.tab-item .tab-item-left {
  padding: 40px 40px 0 0;

}

.tab-item-right img {
  width: 650px;
  height: 480px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper .history-center {
  display: flex !important;
  width: 100%;
  justify-content: center;
}

.history {
  width: 524px !important;
  color: #fff;

  height: 460px;

}

.history .history-title {
  font-size: 7.8125vw;
  line-height: 13.5417vw;
  text-align: center;
}

.history .history-detail {
  width: 100%;
  text-align: left;
  word-break: break-word;
  margin-top: 85px;
}

.year_after {
  width: 30px;
  height: 30px;
  color: #fff;
  position: relative;
  top: 180px;
  left: 95px;
}

.year_before {
  width: 30px;
  height: 30px;
  color: #fff;
  position: relative;
  top: 180px;
  right: 98px;
}

.ant-carousel .slick-prev,
:deep(.ant-carousel .slick-next) {

  top: 35%;


}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.8;
}

.ant-carousel :deep(.slick-dots) {
  display: none !important;
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.row:nth-child(2),
.row:nth-child(3) {
  margin-top: 80px;


}

.row:nth-child(3) {
  .card {
    margin-right: 0;
    height: 250px;
  }
}

.row:nth-child(5) {
  margin: 80px 0;
}

.row .card {
  width: 276px;
  height: 386px;
  padding: 32px 32px 54px 32px;
  background-color: #fff;
  margin-right: 80px;
}

.row .card:last-child {
  margin-right: 0;
}

.row .card img {
  width: 212px;
  height: 300px;
}

.row .card p {
  font-size: 14px;
  height: 21px;
  width: 100%;
  text-align: center;
  color: #000;
  margin: 18px auto;
}

.tab {
  margin-top: 140px;
  margin-bottom: 80px;

}

.tab-item3 {
  color: #fff;
  width: 1028px;
  /* height: 1680px; */
  margin-top: 95px;

}

.tab-item3 .row:nth-child(3) .card img {
  width: 140px;
  height: 198px;
}

.tab-item3 .row:last-child .card img {
  width: 267px;
  height: 180px;
}

.tab-item3 .row:last-child .card {
  height: 262px;
  width: 308px;
  margin-right: 52px;
  padding: 26px 21px;
}

.card .row:nth-child(3) .card {
  margin: 0;
  padding-bottom: 18px;
  height: 244px;
}

.card-foot {
  font-size: 14px;
  height: 40px;
  width: 100%;
  text-align: center;
  color: #000;
  background-color: #fff;
  padding-bottom: 18px;
}

.tab-item-3 {
  color: #fff;
  width: 1028px;
  /* height: 1680px; */
  margin-top: 95px;


  .row .card {
    width: 320px;
    margin-bottom: 50px;
    margin-right: 20px !important;
  }

  .row .card img {
    width: 260px;
  }

  .row .card:nth-child(3n) {
    margin-right: 0 !important;
  }
}

.flex_wrap {
  flex-flow: row wrap
}
</style>
