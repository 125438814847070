<template>
  <div id="home" class="home">
    <a-row>
      <a-col :span="24">
        <div style="height: 680px;"><img src="@/assets/img/header_image_3.png" style="width: 100%;height: 100%;">
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="desc">
    <div class="news-content ">
      <a-row style="height: 100%;">
        <a-col :span="24">
          <div class="row">
            <div class="news" v-for="(item, index) in list.filter((i,idx)=>showMore || (idx < 6))" :key="index" @click="toDetail(item.id)">
              <div class="news-head">
                <div class="left">
                  <p>{{ item.date.slice(8) }}</p>
                  <p>{{ item.date.substring(0, 7) }}</p>
                </div>
                <div class="right">
                  <arrow-right-outlined :style="{ fontSize: '24px' }"/>
                </div>
              </div>
              <div class="news-desc">
                <p>{{ item.title }}</p>
                <p v-if="item.des.length>46">{{ item.des.substring(0, 46) }}...</p>
                <p v-else>{{ item.des }}</p>
              </div>
            </div>
          </div>
        </a-col>
        <div v-if="!showMore" class="showMore" @click="showMore = true">查看更多</div>
      </a-row>
    </div>

  </div>
</template>

<script>
import {ArrowRightOutlined} from '@ant-design/icons-vue';
import {defineComponent} from 'vue';
import news from "@/api/test";

export default defineComponent({
  components: {
    ArrowRightOutlined,
  },
  name: "News",
  data() {
    return {
      list: news,
      showMore:false
    }
  },
  methods: {

    toDetail(id) {
      this.$router.push({
        path: "/newsDetail",
        query: { //query是个配置项
          id: id
        }
      })
    },
  },
})
</script>

<style>
.home {
  height: 680px;
  background-color: rgba(104, 110, 130, 1);
  width: 100%;
  min-width: 1280px;
}
</style>
<style scoped>
.desc {
  height: auto;
  padding: 0 300px;
  min-width: 1280px;
  width: 100%;
  background-color: rgba(28, 31, 43, 1);
  display: flex;
  justify-content: center;
}

.news {
  width: 30%;
  height: 298px;
  background-color: rgba(41, 41, 41, 1);
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  /* margin-right: 40px; */
  border: 1px solid #161922;
  color: rgba(255, 255, 255, 1);
  padding: 0 55px;
  cursor: pointer;
}

.news-content {
  margin-top: 110px;
}

.news:hover {
  background-color: rgba(0, 0, 0, 1);

}

.news-head {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 330px;
  height: 60px;
  margin-top: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.news-head .left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.news-head .left p:nth-child(1) {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.news-head .left p:nth-child(2) {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.news-head .right {
  display: flex;

  width: 50%;
  align-items: center;
  justify-content: end;
}

.news-desc {
  width: 330px;
}

.news-desc p:nth-child(1) {
  margin-top: 18px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 800;
}

.news-desc p:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  margin: 0 auto;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap:42px;
  padding-bottom: 20px;
}
.showMore{
  width: 76px;
  height: 42px;
  border: 1px solid rgba(255, 255, 255, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  color: #ffffff;
}
</style>
