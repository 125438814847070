<template>
    <div id="home" class="home">
        <a-row>
            <a-col :span="24">
                <div style="height: 680px;"><img src="@/assets/img/header_image_2.png" style="width: 100%;height: 100%;">
                </div>
            </a-col>
        </a-row>
    </div>
    <div class="desc">
        <a-row style="height: 100%;margin: 0 auto">
            <a-col span="24" class="product-content">
              <div class="product">
                  <div class="productGroup">远程识别系列</div>
                    <div class="product-desc box" >
                      <ul>
                        <!-- 被 on 标识的元素突出显示 -->
                        <li @mouseover="hover(index)" :class="{on:current===index}"  v-for="(item, index) in list1" >
                            <a @click="toProductDetail(item.id)">
                            <div class="title">{{item.title}}</div>
                            <div class="sub">{{item.desc}}</div>
                            <div class="content">{{item.content}}</div>
                            <div class="btn">查看详情</div>
                            <img :src="item.img"/>
                          </a>
                        </li>
                      </ul>
                    </div>
                </div>
                <div class="product">
                  <div class="productGroup">低空管控系列</div>
                    <div class="product-desc box" >
                      <ul>
                        <!-- 被 on 标识的元素突出显示 -->
                        <li @mouseover="hover(index)" :class="{on:current===index}"  v-for="(item, index) in list2" >
                          <a @click="toProductDetail(item.id)">
                            <div class="title">{{item.title}}</div>
                            <div class="sub">{{item.desc}}</div>
                            <div class="content">{{item.content}}</div>
                            <div class="btn">查看详情</div>
                            <img :src="item.img"/>
                          </a>
                        </li>
                      </ul>
                    </div>
                </div>
              <div class="product">
                <div class="productGroup">船舶管控系列</div>
                <div class="product-desc box" >
                  <ul>
                    <!-- 被 on 标识的元素突出显示 -->
                    <li @mouseover="hover2(index)" :class="{on:current2===index}"  v-for="(item, index) in list3" >
                      <a @click="toProductDetail(item.id)">
                        <div class="title">{{item.title}}</div>
                        <div class="sub">{{item.desc}}</div>
                        <div class="content">{{item.content}}</div>
                        <div class="btn">查看详情</div>
                        <img :src="item.img"/>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="product">
                <div class="productGroup">智慧城市系列</div>
                <div class="product-desc box" >
                  <ul class="">
                    <!-- 被 on 标识的元素突出显示 -->
                    <li @mouseover="hover3(index)" :class="{on:current3===index}"  v-for="(item, index) in list4" :style="`background-image:url(${item.img});background-size:cover;background-position: 25%;`" >
                        <a @click="toProductDetail(item.id)">
                          <div class="title" style="color:#fff;">{{item.title}}</div>
                          <div class="sub" style="color:#fff;">{{item.desc}}</div>
                          <div class="content" style="color:#fff;">{{item.content}}</div>
                          <div class="btn" style="color: #fff; border: 2px solid #fff;">查看详情</div>
                        </a>
                    </li>
                  </ul>
                </div>
              </div>
<!--                <div class="product">-->
<!--                    <div class="product-head">-->
<!--                        <img src="@/assets/img/icon2.png" alt="">-->
<!--                    </div>-->
<!--                    <div class="product-desc">-->
<!--                        <p>ADS-B</p>-->
<!--                        <p>飞行器广播式自动相关监视系统</p>-->
<!--                        <p @click="toProductDetail(4)">ADSR-800</p>-->
<!--                        <p @click="toProductDetail(5)"></p>-->
<!--                        &lt;!&ndash; <p>查看更多</p> &ndash;&gt;-->
<!--                    </div>-->
<!--                </div>-->



            </a-col>
        </a-row>

    </div>
</template>

<script>
import { SettingOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons-vue';
import { defineComponent } from 'vue';
export default defineComponent({
    components: {
        SettingOutlined,
        EditOutlined,
        EllipsisOutlined,
    },
    name: "Product",
    data() {
        return {
          current3:0,
          current2:0,
          current:0,
          list1:[
            {
              id:1,
              group:"#远程识别系列#",
              title:"无人机远程识别模块（嵌入式）",
              desc:"RR-Pioneer",
              content: "无人驾驶航空器在飞行过程中通过无线局域网 (Wi-Fi) 或蓝牙自动广播识别信息，Wi-Fi 频段及信道满足广播频段在2400MHz至2476MHZ或5725MHz至5829 MHz的范国内，远程识别报文内容、报文格式、传输模式和性能均满足运行识别最低性能要求。",
              href:"",
              img:"product/remote.png"
            },
            {
              id:2,
              group:"#远程识别系列#",
              title:"无人机远程识别外挂模块",
              desc:"飞手小方",
              content: "新规强制规定我国的专业飞手应当将他们的无人机信息数字化，我司因而研发了远程识别“飞手小方”，并已获得FAA官方认证。该外挂式独立模块可使无人机完全符合无人机飞行要求标准，无需购买新的无人机。",
              href:"",
              img:"product/RR-Pioneer.png"
            },
            {
              id:3,
              group:"#远程识别系列#",
              title:"无人机远程识别地面站",
              desc:"RR-Pioneer Vision",
              content:"RR-pioneer vision远程识别地面站由多个无线电接口组成，用于收集远程识别信号，10/ 100M 以太网接口符合国际标准\n" +
                  "国标：GB 42590-2023 \n" +
                  "欧盟：ASD-STAN DIN EN 4709-002 \n" +
                  "美国：ASTM Remote ID Standard ASTM F3411-22a-RID-B/ F3586-22",
              href:"",
              img:"product/rr.png"
            }
          ],
          list2:[
            {
              id:4,
              title:"无人机避碰模块",
              group:"#低空管控系列#",
              desc:"ADSR800",
              content: "采用MavLink2.0协议兼容Pixhawk4飞控平台，采用直放式无线电接收技术，即插即用，帮助无人机自主实现避碰功能，体积小巧且高速可靠，适用于无人机、空域监控。",
              href:"",
              img:"news/ADSR800.png"
            },
            {
              id:5,
              group:"#低空管控系列#",
              title:"单兵飞机信号接收模块",
              desc:"ADSR-800",
              content:"直放式无线电接收技术的ADS-B接收机，具有超宽的动态范围，可滤除大部分带外噪声，接收和解码几乎没有延迟，适用于信号接收基站、单兵接收。\n" +
                  "国标：GB 42590-2023 \n" +
                  "欧盟：ASD-STAN DIN EN 4709-002 \n" +
                  "美国：ASTM Remote ID Standard ASTM F3411-22a-RID-B/ F3586-22",
              href:"",
              img:"product/AIS200.png"
            },
            {
              id:6,
              group:"#低空管控系列#",
              title:"无人机AIS数据采集模块",
              desc:"AIS112E",
              content: "星基AIS接收载荷，拥有2路独立解调硬件和2次变频技术，可捕获极微小的AIS信号，获得良好的在轨接收效果，适用于微纳卫星、海上救援。",
              href:"",
              img:"news/AIS112.png"
            },
          ],
          list3:[
            {
              id:7,
              group:"#船舶管控系列#",
              title:"AIS 射频信号发射模组",
              desc:"AIT33",
              content:"适用于AIS信号收发器、AIS-MOB（海上救生设备）、无人船及海上搜救方案等。",
              href:"",
              img:"news/AIT33.png"
            },
            {
              id:8,
              group:"#船舶管控系列#",
              title:"AIS数据采集模块",
              desc:"AIS112E",
              content: "拥有2路独立解调硬件的AIS接收器，2次变频技术，多窄带滤波器滤除大部分带外噪声，极高灵敏度和接收效果并自动调节信号，适用于沿海基站。",
              href:"",
              img:"news/AIS112E.png"
            },
            {
              id:9,
              group:"#船舶管控系列#",
              title:"AIS个人便携应急数位标",
              desc:"AIS-MOB",
              content: "AIS-MOB 是一种可安装于救生衣或随身携带的便携式应急示位报警装备。该设备浸水后便自动开启并触发\n" +
                  "警报，自动发射求救信号至附近船舶。可连续工作 48 小时以上，无需定位系统的支持。求救信号被接收后\n" +
                  "会在船舶电子海图或者 AIS 设备上发出警报，并显现落水人员的经纬度坐标、速度、航向、方位、行动轨\n" +
                  "迹等基本信息，以便救援人员快速找到落水者。",
              href:"",
              img:"news/AIS-MOB.png"
            },
          ],
          list4:[
            {
              id:10,
              group:"#智慧城市系列#",
              title:"勤务数字化定位系统(移动端)",
              desc:"",
              content:"勤务数字化定位系统（移动端）采用前后端分离模式，主要有人员管理、设备管理、产品管理、车辆位置可视化展示对上层指挥调度平台的兼容等，为用户提供可视化界面和有效的信息位置，有利于指挥中心更加快捷地做出决策和人力部署。",
              href:"",
              img:"product/qinwu.png"
            },
            {
              id:11,
              group:"#智慧城市系列#",
              title:"非机动车智能管控系统",
              desc:"",
              content:"非机动车智能管控系统 运用了物联网设备的数据采集和处理，主要由移动 2G 通讯技术、GPS 卫星定位技\n" +
                  "术、外置高精度温度传感探头技术、继电器技术、单片机定时技术组成，主要实现非机动车智能化管理：\n" +
                  "车辆充电防火断电预警和营运电动车改装电池行为监测的电动车充电防火。\n" +
                  "产品安装在电池充电口上。在车辆充电口上加装后，无需接线、即插即用。",
              href:"",
              img:"product/fjd.png"
            },
            {
              id:12,
              group:"#智慧城市系列#",
              title:"工牌记录仪",
              desc:"",
              content: "工牌记录仪是一款具有音视频记录功能的可佩戴智能电子工牌，设备采用电子墨水屏设计，可灵活配置电子工牌信息。无线系列摄像机（渠道）安装简单、易操作、无需布网线，非常适合中小型应用场景，如家庭/住宅、小型零售店和其他典型的中小企业应用场景等。",
              href:"",
              img:"product/gp.png"
            },
          ]
        }
    },
    methods: {
      hover(index){
        let that=this
        that.current=index
      },
      hover2(index){
        let that=this
        that.current2=index
      },
      hover3(index){
        let that=this
        that.current3=index
      },
      toProductDetail(id) {
        this.$router.push({
          path: "/productDetail",
          query: { //query是个配置项
            tab: id
          }
        })
      }
    },
})
</script>

<style  >
.home {
    height: 680px;
    background-color: rgba(104, 110, 130, 1);
    width: 100%;
    min-width: 1280px;
}
</style>
<style scoped>
.desc {
    padding: 0 300px;
    min-width: 1280px;
    width: 100%;
    background-color: rgba(28, 31, 43, 1);
    display: flex;
    align-items: center;
}

.product-content {
    display: flex;
    flex-direction: column;
}

.product {

    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 150px 0 0 0;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}



.product:hover .product-head {
    background-image: url(@/assets/img/six1.png);
}

.product-head {
    background-image: url(@/assets/img/six.png);
    background-repeat: no-repeat;
    width: 120px;
    height: 120px;
    margin-top: 66px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-head img {
    width: 50%;
    height: 50%;
}

.product-desc {
    width: 100%;
}
.productGroup{

  /** 文本1 */
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 69.5px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
  width: 100%;
  text-align: left;
  margin-bottom: 38px;
}
.productGroup::after{
  content: "";
  display: block;
  width: 190px;
  height: 4px;
  opacity: 1;
  background: rgba(53, 148, 255, 1);


}
.product-desc p:nth-child(1) {
    margin-top: 29px;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
}

.product-desc p:nth-child(3),
p:nth-child(4),p:nth-child(5) {
    width: 80%;
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 130px;
    height: 42px;
    line-height: 42px;
    margin: 0 auto;
}

.product-desc p:nth-child(3):hover,
p:nth-child(4):hover,p:nth-child(5):hover {
    border: 1px solid rgba(51, 194, 255, 1);
    color: rgba(51, 194, 255, 1);
}

.product-desc p:nth-child(4) {
    margin-top: 20px;
}

.product-desc p:nth-child(3) {
    margin-top: 166px;
}

.product-desc p:nth-child(5) {
    margin-top: 20px;
}
.box ul{
  display: flex;
  padding: 0;
}
.box ul li {
  float: left;
  list-style: none;
  width: 295px;
  height: 649px;
  text-align: center;
  padding: 30px;
  background-color: white;
  box-shadow: 3px 2px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  justify-content: center;
  /* 元素属性发生改变时延迟1s,淡出效果 */
  transition: all 1s ease-out;
}

.box ul li a {
  text-decoration: none;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.box ul li img {
  height: 260px;
  width: 260px;
  margin-top: 154px;

}

.box ul li .title {
  width: 100%;

  margin-top: 74px;
  /** 文本1 */
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 52.13px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;

}
.box ul li  .content {
  display: none;

}
.box ul li .sub {
  /** 文本1 */
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 34.75px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
   width: 100%;
}

.box ul li .btn {
  display: none;


}


/* 被标注后的样式 */
.box ul .on {
  width: 480px;
  padding: 0 30px;
  text-align: left;
  display: flex;
}

.box ul .on .title {



}

.box ul .on .sub {

  color: black;
}
.box ul .on a{
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.box ul .on .btn{
  display: block;
  width: 96px;
  height: 35px;
  line-height: 34px;
  color: black;
  border: 2px solid black;
  border-radius: 19px;
  text-align: center;
  margin-top: 50px;
}
.box ul .on img {
  margin-top: 30px;
  height: 260px;
  width: 260px;
  bottom: 0;
  right: 60px;
  position: absolute;
}

.box ul .on .content {
  display: block;
  color: black;
  margin-top: 80px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(102, 102, 102, 1);
  text-align: left;
  vertical-align: top;

}
.color-white{
  color: #fff;
}
</style>
