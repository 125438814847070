<template>
    <div id="home" class="home">
        <a-row>
            <a-col :span="24">
                <div style="height: 680px;"><img src="@/assets/img/header_image_4.png"
                        style="width: 100%;height: 100%;">
                </div>
            </a-col>
        </a-row>
    </div>
    <div class="desc">
        <div class="contact-content ">
            <a-row style="height: 100%;">
                <!-- <a-tabs v-model:activeKey="tab" centered :tabBarGutter="300">
                    <a-tab-pane key="1" tab="客服热线" > -->
                <div class=" tab">
                    <div class="tab-item">
                        <div class="tab-item-left">
                            <div class="left-zh">
                                <p>与我们取得联系</p>
                            </div>
                            <div class="left-en">
                                <p>CONTACT</p>
                                <p>US</p>
                            </div>

                        </div>
                        <div class="tab-item-right">
                            <div>
                                <span>
                                    <phone-outlined :rotate=90 :style="{ fontSize: '80px' }" />
                                </span>
                                <span>021-56698901</span>
                            </div>
                            <p>（周一至周五 9:00—18:00）</p>
                            <div>
                                <span>
                                    <mail-outlined :style="{ fontSize: '80px' }" />
                                </span>
                                <span>epro@paistars.com</span>
                            </div>
                        </div>
                        <div class="contact-foot">
                            <div class="foot-top">
                                <div>公司地址</div>
                                <div class="address-en">
                                    <p>COMPANY</p>
                                    <p>ADDRESS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="foot-bottom" @click="goMap">
                        <img src="@/assets/img/map_image.png" alt="">
                    </div>
                </div>
                <!-- </a-tab-pane> -->
                <!-- <a-tab-pane key="2" tab="加入我们" >
                        <div class="tab-item2">
                            <div class="tab-item-left">
                                <div class="left-zh">
                                    <p>热聘职位</p>
                                </div>
                                <div class="left-en">
                                    <p>HOT</p>
                                    <p>JOBS</p>
                                </div>

                            </div>
                            <div class="tab-item-center">
                                <div class="item-center">
                                    <p>项目助理</p>
                                    <p>工作地点：上海</p>
                                    <p>工作经验：1-3年</p>
                                    <p>了解详情</p>
                                </div>
                                <div class="item-center">
                                    <p>项目助理</p>
                                    <p>工作地点：上海</p>
                                    <p>工作经验：1-3年</p>
                                    <p>了解详情</p>
                                </div>
                                <div class="item-center">
                                    <p>项目助理</p>
                                    <p>工作地点：上海</p>
                                    <p>工作经验：1-3年</p>
                                    <p>了解详情</p>
                                </div>
                            </div>

                        </div>
                    </a-tab-pane> -->
                <!-- </a-tabs> -->
            </a-row>
        </div>

    </div>
</template>

<script>
import { PhoneOutlined, MailOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router'
import { defineComponent, getCurrentInstance, onUpdated } from 'vue';
export default defineComponent({
    components: {
        PhoneOutlined,
        MailOutlined
    },
    name: "News",
    data() {
        return {
            tab: "1",
        }
    },
    setup(props) {
        const route = useRoute()
        const ins = getCurrentInstance();
        onUpdated(() => {
            ins.data.tab = route.query.tab
        })
    },
    created() {
        let that = this
        const route = useRoute();
        if (route.query.tab) {
            that.tab = route.query.tab
        }
    },
    watch: {
        $route: {
            handler: function (route) {
                const query = route.query
                let that = this
                if (query) {
                    that.tab = route.query.tab
                }
            }
        }
    },
    methods: {
        goMap() {
            location.href = `https://uri.amap.com/marker?position=121.470698,31.396229&name=忆伯科技&src=忆伯官网`
        }
    },
})
</script>

<style>
.home {
    height: 680px;
    background-color: rgba(104, 110, 130, 1);
    width: 100%;
    min-width: 1280px;
}
</style>
<style scoped>
.desc {
    /* padding: 0 300px; */
    min-width: 1280px;
    width: 100%;
    background-color: rgba(28, 31, 43, 1);
    display: flex;
    justify-content: center;
}

.contact-content {
    /* margin-top: 150px; */
    margin-top: 95px;
}

.ant-tabs :deep(.ant-tabs-nav .ant-tabs-tab) {
    color: #000;
}

.ant-tabs :deep(.ant-tabs-nav .ant-tabs-tab-btn) {
    background-image: url(@/assets/desc2.png);
    color: #fff;
    background-color: #1c1f2b;
    width: 160px;
    height: 42px;
    text-align: center;
    line-height: 42px;
}

.ant-tabs :deep(.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: #000;

    background-image: url(@/assets/desc.png);

    width: 160px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    background-size: cover;
}

.ant-tabs-top :deep(.ant-tabs-nav::before,
    .ant-tabs-bottom>.ant-tabs-nav::before) {
    border-bottom: none;
}

.ant-tabs :deep(.ant-tabs-ink-bar) {
    background: none;
}

.tab {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tab-item {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-top: 95px; */

}

.tab-item2 {
    color: #fff;
    display: flex;
    flex-direction: column;
    width: 1300px;
    height: 480px;
}

.tab-item2 .tab-item-left {


    display: flex;
    flex-direction: row;
    margin-top: 120px;
}


.tab-item .tab-item-left {

    height: 58px;
    display: flex;
    flex-direction: row;
    /* margin-top: 120px; */
}

.tab-item .tab-item-right {

    height: 150px;
    display: flex;
    margin-left: 360px;
    margin-top: 44px;
    flex-direction: column;
}

.tab-item .tab-item-right span:nth-child(2) {
    font-size: 72px;
    font-weight: 600;
    margin-left: 40px;
}

.tab-item .tab-item-left .left-en {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 62px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 16px;
}

.tab-item2 .tab-item-left .left-en {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    height: 62px;
    font-weight: 600;
    font-size: 16px;
}

.tab-item-left .left-zh p {
    font-size: 40px;
    font-weight: 700;
    margin: 0;
    height: 62px;
}


.left-en p {
    margin: 0;
}


.contact-foot {
    display: flex;
    flex-direction: column;
    width: 248px;
    margin-top: 145px;
}

.contact-foot .foot-top {
    font-size: 40px;
    font-weight: 700;
    display: flex;
    height: 62px;
}

.foot-bottom {
    width: 100%;
    height: 560px;
    margin-top: 40px;
    cursor: pointer;
}

.foot-bottom img{
    width: 100%;
    height: 560px;
}

.contact-foot .address-en {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    height: 62px;

}

.contact-foot .address-en p {
    margin: 0;
}

.ant-carousel :deep(.slick-dots) {
    display: none !important;
}

.tab-item-center {
    margin-left: 160px;

}

.tab-item-center .item-center {
    height: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #fff;
}

.tab-item-center .item-center p:nth-child(1) {
    font-size: 20px;
    font-weight: 700;
    width: 80px;
}

.tab-item-center .item-center p:nth-child(2) {
    font-size: 14px;
    font-weight: 400;

    width: 306px;
}

.tab-item-center .item-center p:nth-child(3) {
    font-size: 14px;
    font-weight: 400;

    width: 250px;
}

.tab-item-center .item-center p:nth-child(4) {
    font-size: 14px;
    font-weight: 400;

    width: 160px;
    background-image: url(@/assets/img/more-detail.png);
    background-size: 160px;
    height: 45px;
    line-height: 40px;
    background-repeat: no-repeat;
    text-align: center;


}
</style>
