<template>
  <router-view name="header"></router-view>
  <!-- 路由出口 -->
  <!-- 路由匹配到的组件将渲染在这里 -->
  <router-view key="routerKey"></router-view>
  <router-view name="footer"></router-view>
</template>

<script>


export default {
  name: 'App',
  components: {
  },


}
</script>

<style>
body {
  min-width: 1280px;
}
</style>
