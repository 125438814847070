<template>
    <!-- 头部 -->
    <div class="header" :class="{ 'fixed-header': isFixed }">
        <div class="logo"><img alt="logo" src="@/assets/img/logo-w.png"></div>
        <a-space size="large" class="nav-route">
            <!-- 使用 router-link 组件来导航. -->
            <!-- 通过传入 `to` 属性指定链接. -->
            <!-- <router-link> 默认会被渲染成一个 `<a>` 标签 -->
            <a-menu v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" mode="horizontal"
                @click="menuClick">
                <a-menu-item key="home">
                    <router-link to="/home" replace>首页</router-link>
                </a-menu-item>
                <a-sub-menu key="about">
                    <template #title>
                        <router-link to="/about?tab=1">关于我们</router-link>
                    </template>
                    <a-menu-item key="about:1" class="nav-item">公司简介</a-menu-item>
                    <a-menu-item key="about:2" class="nav-item">公司历史</a-menu-item>
                    <a-menu-item key="about:3" class="nav-item">荣誉资质</a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="product">
                    <template #title>
                        <router-link to="product">产品中心</router-link>
                    </template>
                    <a-menu-item key="product:1" class="nav-item">远程识别系列</a-menu-item>
                    <a-menu-item key="product:4" class="nav-item">低空管控系列</a-menu-item>
                    <a-menu-item key="product:7" class="nav-item">船舶管控系列</a-menu-item>
                    <a-menu-item key="product:10" class="nav-item">智慧城市</a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="news">
                    <template #title>
                        <router-link to="/news">新闻动态</router-link>
                    </template>

                    <!-- <a-menu-item key="news:1">最新资讯</a-menu-item>
                                <a-menu-item key="news:2">更多动态</a-menu-item> -->
                </a-sub-menu>
                <a-sub-menu key="contact">
                    <template #title>
                        <router-link to="/contact?tab=1">联系我们</router-link>
                    </template>
                    <!-- <a-menu-item key="contact:2">加入我们</a-menu-item> -->
                    <!-- <a-menu-item key="contact:1">客服热线</a-menu-item> -->
                </a-sub-menu>
            </a-menu>
        </a-space>
    </div>
</template>

<script>

import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons-vue';
export default {
    name: "Nav",
    components: {
        MailOutlined,
        AppstoreOutlined,
        SettingOutlined,
    },
    data() {
        return {
            selectedKeys: ['home'],
            openKeys: ['1'],
            menuCurrent: 1,
            isFixed: false,
            lastScrollPosition: 0,
            fixedPosition: 100, // 在距离多少px后固定头部
        }
    },
    
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        // 点击菜单，路由跳转,注意的是当点击MenuItem才会触发此函数
        menuClick({ item, key, keyPath }) {
            let pathArr = key.split(":")
            let tab = pathArr.length == 2 ? pathArr[1] : 1;

            if (pathArr[0] == 'product') {
                pathArr[0] = 'productDetail'
            }
            this.$router.push({
                path: "/" + pathArr[0],
                query: { //query是个配置项
                    tab: tab,
                }
            })
        },
        // 头部固定定位滑动改变样式
        handleScroll() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            this.isFixed = currentScrollPosition > 0
            this.lastScrollPosition = currentScrollPosition;
            console.log(currentScrollPosition)
        },
    },
    setup() {

    },
}
</script>
<style>
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
    border-bottom: 0 !important;
}
</style>
<style scoped>
a {
    text-decoration: none;
    color: #333;
}

a:hover,
a:visited,
a:link,
a:active {
    color: #333;
    /* 设置使所有a标签的四种状态都和本身颜色保持一致，样式代码自己写 */
}



.router-link-active {
    border-bottom: 2px solid #1890ff;
    padding-bottom: 10px;
}



.header {
    position: fixed;
    top: 0;
    left: 0;
    height: 90px;
    width: 100%;
    min-width: 1280px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-image: linear-gradient(120deg,#1C1F2B 100%, #1C1F2B 0%); */
    background-color: #0d111b;
    padding: 0 300px;
    background-color: rgba(255, 255, 255, 0);
    z-index: 999;
}
.fixed-header {
    background-color: #0d111b;
}
/* logo */
.logo {
    height: 63px;
    width: 246px;
    display: flex;
    align-items: center;
}

.logo img {
    height: 63px;
    width: 246px;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: end;
}

/* 菜单 */
.ant-menu-horizontal {
    border-bottom: none;
}

.ant-menu {
    background-color: rgba(255, 255, 255, 0);
    z-index: 999;
}

.ant-menu a {
    color: #fff;
    font-size: 20px;
}

.nav-item {
    font-size: 20px !important;
}

.ant-menu-horizontal>.ant-menu-item a {
    color: #fff;
}

.router-link-active {
    border-bottom: 2px solid #fff;
}

.ant-space-align-center {
    align-items: center;
    height: 90px;
}
</style>
