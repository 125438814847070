<template>
  <div id="box">
    <ul>
      <!-- 被 on 标识的元素突出显示 -->
      <li @mouseover="hover(index)" :class="{on:current===index}"  v-for="(item, index) in list" >
        <a href="#">
          <div class="title">{{item.title}}</div>
          <div class="sub">{{item.desc}}</div>
          <div class="btn">Go</div>
          <img :src="item.img"/>
        </a>
      </li>


    </ul>

  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
  name: "test",
  data() {
    // 这里存放数据
    return {
      current:0,
      list:[
        {
          title:"手机配件",
          desc:9.9,
          href:"",
          img:"news/new10.png"
        },
        {
          title:"手机好货",
          desc:"爆款现货抢购",
          href:"",
          img:"news/new9.png"
        },
        {
          title:"游戏手机",
          desc:"白条24期免息",
          href:"",
          img:"news/new8.png"
        },
        {
          title:"新机首发",
          desc:"新机",
          href:"",
          img:"news/new7.jpg"
        },
      ]
    }
  },
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  // 方法集合
  methods: {
    hover(index){
      let that=this
      that.current=index
    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  //过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载之前
  beforeMount() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
  },
  // 生命周期 - 更新之前
  beforeUpdate() {
  },
  // 生命周期 - 更新之后
  updated() {
  },
  // 生命周期 - 销毁之前
  beforeDestroy() {
  },
  // 生命周期 - 销毁完成
  destroyed() {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  //进入的时候触发
  activated() {
  },
  //离开的时候触发
  deactivated() {
  },
}
</script>

<style   scoped>
#box ul{
  display: flex;
}
#box ul li {
  float: left;
  list-style: none;
  width: 200px;
  height: 250px;
  text-align: center;
  background-color: white;
  box-shadow: 3px 2px 30px rgba(0, 0, 0, 0.1);
  position: relative;

  /* 元素属性发生改变时延迟1s,淡出效果 */
  transition: all 1s ease-out;
}

#box ul li a {
  text-decoration: none;
}

#box ul li img {
  height: 170px;
  width: 120px;
  position: absolute;
  right: 15px;
}

#box ul li .title {
  width: 100%;
  height: 30px;
  margin-top: 20px;
  font-size: 16px;
  color: #333;
}

#box ul li .sub {
  width: 100%;
  height: 30px;
  font-size: 14px;
  color: #666;
}

#box ul li .btn {
  display: none;
}


/* 被标注后的样式 */
#box ul .on {
  width: 400px;
  padding-left: 30px;
}

#box ul .on .title {
  text-align: left;
  color: black;
}

#box ul .on .sub {
  text-align: left;
  color: black;
}

#box ul .on img {
  height: 230px;
  bottom: 0;
  right: 0;
}

#box ul .on .btn {
  width: 75px;
  height: 34px;
  line-height: 34px;
  display: block;
  color: black;
  border: 2px solid black;
  border-radius: 19px;
  margin-top: 30px;
}

</style>
