<template>
    <div id="home" class="home">
        <a-row>
            <a-col :span="24">
                <div style="height: 680px;"><img src="@/assets/img/header_image_2.png"
                        style="width: 100%;height: 100%;">
                </div>
            </a-col>
        </a-row>
    </div>
    <div class="desc">
        <a-row style="height: 100%;">
            <a-col :span="24">
                <a-tabs v-model:activeKey="active" centered :tabBarGutter="160" class="tab" @change="buy">
                    <a-tab-pane key="0" tab="方案简介">
                        <div class="tab-item">
                            <div class="tab-item-left">
                                <div style="display: flex;justify-content: space-between">
                                    <span class="font-24">{{ detail.title }}</span>
                                    <span class="font-24">{{ detail.group }}</span>
                                </div>
                                <p style="font-size: 14px; font-weight: 400;margin-top: 60px;" v-html="detail.content">
                                </p>
                                <button v-if="detail.pdf" style="color: #000;cursor: pointer;"
                                    @click="download(detail.pdf)">查看产品文档</button>
                                <div class="height">
                                    <!-- <h3>主要特点：</h3>
                                    <span v-for="(item, index) in detail.height" :key="index">{{ item }}</span> -->
                                </div>
                            </div>
                            <div class="tab-item-right"><img :src="detail.cover" alt=""></div>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="1" :tab=detail.tab1 force-render v-if=detail.imgs.length>
                        <div class="tab-item">
                            <div class="display">
                                <div class="img" v-for="(item, index) in detail.imgs" :key="index">
                                    <div><img :src=item.src alt=""></div>
                                    <div class="back">
                                        <p>{{ item.des }}</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </a-tab-pane>
                    <a-tab-pane key="2" :tab=detail.tab3>
                        <div class="tab-item">
                            <img class="params-img" :src="detail.params" alt="">
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="前往购买" v-if="detail.id < 8 || detail.id === 9">

                    </a-tab-pane>
                </a-tabs>

            </a-col>
            <div class="foot">
                <a-col :span="8" style="display: flex;justify-content: end;">
                    <div class="left" @click="sub(detail.sort)" v-show="detail.sort - 1 > 0">上一个</div>
                </a-col>
                <a-col :span="8" style="display: flex;justify-content: center;">
                    <div class="center">{{ detail.title }}</div>
                </a-col>
                <a-col :span="8" style="display: flex;justify-content: start;">
                    <div class="right" @click="pre(detail.sort)" v-show="detail.sort + 1 <= list.length">下一个</div>
                </a-col>
            </div>
        </a-row>

    </div>
</template>

<script>
import { defineComponent } from 'vue';

import { useRoute } from 'vue-router'
import products from "@/api/product";


export default defineComponent({
    components: {

    },
    name: "About",
    data() {
        return {
            tab: "1",
            active: "0",
            detail: "",
            list: products,
        }
    },
    created() {
        let that = this
        const route = useRoute();
        if (route.query.tab) {
            let id = route.query.tab

            that.list.forEach(item => {

                if (item.id == parseInt(id)) {
                    that.detail = item
                }
            });

        }
    },
    watch: {
        $route: {
            handler: function (route) {
                const query = route.query
                console.log(query)
                if (query) {
                    this.redirect = query.redirect

                    this.list.forEach(item => {

                        if (item.id == parseInt(query.tab)) {
                            this.detail = item
                        }
                    });
                }
            }
        },

    },
    setup(props) {

    },
    methods: {
        download(src) {
            window.open(src, "_blank");
            window.location.reload()
        },
        sub(sort) {
            let that = this
            sort = sort - 1
            that.list.forEach(item => {

                if (item.sort == sort) {
                    that.detail = item
                }
            });
        },
        buy(tabKey) {
            if (tabKey == "3") {
                let url = ''
                switch (this.detail.id) {
                    case 1:
                        url = "https://item.taobao.com/item.htm?id=778299476659&spm=a21xtw.29178619"
                        window.open(url, "_blank");
                        window.location.reload()
                        break
                    case 2:
                        url = "https://item.taobao.com/item.htm?id=799285309084&spm=a21xtw.29178619"
                        window.open(url, "_blank");
                        window.location.reload()
                        break
                    case 3:
                        url = "https://item.taobao.com/item.htm?id=803804309451&spm=a21xtw.29178619"
                        window.open(url, "_blank");
                        window.location.reload()
                        break
                    case 4:
                        url = "https://item.taobao.com/item.htm?id=712218773930&spm=a21xtw.29178619"
                        window.open(url, "_blank");
                        window.location.reload()
                        break
                    case 5:
                        url = "https://item.taobao.com/item.htm?id=712603731660&spm=a21xtw.29178619"
                        window.open(url, "_blank");
                        window.location.reload()
                        break
                    case 6:
                        url = "https://item.taobao.com/item.htm?id=712210497961&spm=a21xtw.29178619"
                        window.open(url, "_blank");
                        window.location.reload()
                        break
                    case 7:
                        url = "https://item.taobao.com/item.htm?id=712591914707&spm=a21xtw.29178619"
                        window.open(url, "_blank");
                        window.location.reload()
                        break  
                    case 8:
                        url = "https://item.taobao.com/item.htm?id=726957023401&spm=a21xtw.29178619"
                        window.open(url, "_blank");
                        window.location.reload()
                        break    
                    case 9:
                        url = "https://item.taobao.com/item.htm?id=714986503411&spm=a21xtw.29178619"
                        window.open(url, "_blank");
                        window.location.reload()
                        break
                    default:
                        break
                }
            }
        },
        pre(sort) {
            let that = this
            sort = sort + 1
            that.list.forEach(item => {

                if (item.sort == sort) {
                    that.detail = item
                }
            });
        }
    },
})
</script>

<style>
.home {
    height: 680px;
    background-color: rgba(104, 110, 130, 1);
    width: 100%;
    min-width: 1280px;
}
</style>
<style scoped>
.desc {
    padding: 0 300px;
    min-width: 1280px;
    width: 100%;
    background-color: rgba(28, 31, 43, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(@/assets/product/bg4.png);
    background-size: cover;
}

.ant-tabs :deep(.ant-tabs-nav .ant-tabs-tab) {
    color: #000;
}

.ant-tabs :deep(.ant-tabs-nav .ant-tabs-tab-btn) {
    background-image: url(@/assets/desc2.png);
    color: #fff;
    width: 160px;
    height: 42px;
    text-align: center;
    line-height: 42px;
}

.ant-tabs :deep(.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: #000;
    background-image: url(@/assets/desc.png);
    width: 160px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    background-size: cover;
}

.ant-tabs-top :deep(.ant-tabs-nav::before,
    .ant-tabs-bottom>.ant-tabs-nav::before) {
    border-bottom: none;
}

.ant-tabs :deep(.ant-tabs-ink-bar) {
    background: none;
}

.tab {
    margin-top: 140px;
}

.tab-item {
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 1300px;
    margin-top: 95px;

}

.tab-item .tab-item-left,
.tab-item-right {
    width: 50%;
    height: 100%;
}

.tab-item .tab-item-left {
    padding: 40px 40px 0 0;
}

.tab-item .tab-item-left .height h3 {
    color: #fff;
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;

}

.tab-item .tab-item-left .height span {
    margin-right: 32px;
}

.tab-item-right img {
    width: 620px;
    height: 380px;
}

.tab-item img.params-img {
    width: 1280px;
    /* height: 514px; */
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper .history-center {
    display: flex !important;
    width: 100%;
    justify-content: center;
}

.history {
    width: 524px !important;
    color: #fff;

    height: 460px;

}

.history .history-title {
    font-size: 7.8125vw;
    line-height: 13.5417vw;
    text-align: center;
}

.history .history-detail {
    width: 100%;
    text-align: left;

    margin-top: 85px;
}

.year_after {
    width: 30px;
    height: 30px;
    color: #fff;
    position: relative;
    top: 180px;
    left: 95px;
}

.year_before {
    width: 30px;
    height: 30px;
    color: #fff;
    position: relative;
    top: 180px;
    right: 98px;
}

.ant-carousel .slick-prev,
:deep(.ant-carousel .slick-next) {

    top: 35%;


}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    opacity: 0.3;
    z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
    display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
    opacity: 0.8;
}

.ant-carousel :deep(.slick-dots) {
    display: none !important;
}

.row {
    display: flex;
    width: 100%;
    justify-content: center;
}

.row:nth-child(2) {
    margin-top: 80px;
}

.row:nth-child(3) {
    margin-top: 80px;
}

.row .card {
    width: 276px;
    height: 300px;
    padding: 32px 32px 54px 32px;
    background-color: #fff;
    margin-right: 80px;
}

.row .card img {
    width: 100%;
    height: 100%;
}

.row .card p {
    font-size: 14px;
    height: 21px;
    width: 100%;
    text-align: center;
    color: #000;
    margin: 18px auto;
}

.tab-item .display {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.tab-item .display .img:nth-child(1) {
    margin-left: 0;
}

.tab-item .display .img {
    width: 295px;
    height: 295px;
    margin-left: 40px;
    display: flex;
    align-items: center;

}

.tab-item .display .img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.tab-item .display .back {
    z-index: 1;
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 295px;
}

.tab-item .display .back p {
    color: #fff;
    margin: 0;
    text-align: center;
    position: relative;
    font-size: 16px;
    font-weight: 700;
}

.foot {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 40px;
    margin-top: 40px;
    width: 100%;
}

.foot .left {
    width: 166px;
    height: 45px;
    line-height: 45px;
    cursor: pointer;
    color: #fff;
    text-align: center;
    background-image: url(@/assets/img/left.png);
    background-size: cover;

}

.foot .left:hover {
    background-image: url(@/assets/img/left-w.png);
    color: #000;
}

.foot .right:hover {
    background-image: url(@/assets/img/more-detail-w.png);
    color: #000;
}

.foot .center {
    width: 166px;
    height: 45px;
    line-height: 45px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    background-image: url(@/assets/desc.png);
    background-size: cover;
}

.foot .right {
    width: 172px;
    height: 45px;
    cursor: pointer;
    line-height: 45px;
    color: #fff;
    text-align: center;
    background-image: url(@/assets/img/more-detail.png);
    background-size: cover;
}



.product-content {
    display: flex;
    justify-content: space-between;
}

.product {
    width: 400px;
    min-width: 400px;
    height: 690px;
    background-color: #161922;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 150px 0 0 40px;
    text-align: center;
    border: 1px solid #161922;
    color: rgba(255, 255, 255, 1);
}

.product:hover {
    background-color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(51, 194, 255, 1);
    cursor: pointer;
}

.product:hover .product-head {
    background-image: url(@/assets/img/six1.png);
}

.font-24 {
    font-size: 24px;
}

.product-head {
    background-image: url(@/assets/img/six.png);
    background-repeat: no-repeat;
    width: 120px;
    height: 120px;
    margin-top: 66px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-head img {
    width: 50%;
    height: 50%;
}

.product-desc {
    width: 100%;
}

.product-desc p:nth-child(1) {
    margin-top: 29px;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
}

.product-desc p:nth-child(3),
p:nth-child(4) {
    width: 80%;
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 130px;
    height: 42px;
    line-height: 42px;
    margin: 0 auto;
}

.product-desc p:nth-child(3):hover,
p:nth-child(4):hover {
    border: 1px solid rgba(51, 194, 255, 1);
    color: rgba(51, 194, 255, 1);
}

.product-desc p:nth-child(4) {
    margin-top: 20px;
}

.product-desc p:nth-child(3) {
    margin-top: 166px;
}

.product-desc p:nth-child(5) {
    margin: 0 auto;
    margin-top: 90px;
    width: 76px;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid rgba(255, 255, 255, 1);
}
</style>
