import { createApp,Vue } from 'vue'
import './style.css'
import router from './router/index';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/antd.css';

const app = createApp(App);


app.use(Antd).use(router).mount('#app');
