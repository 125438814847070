import { createRouter, createWebHashHistory } from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Home from '@/views/home/home.vue'
import Nav from '@/views/nav/index.vue'
import Footer from '@/views/footer/index.vue'
import About from '@/views/about/index.vue'
import Product from '@/views/product/index.vue'
import News from '@/views/news/index.vue'
import NewsDetail from '@/views/news/detail.vue'
import ProductDetail from '@/views/product/detail.vue'
import Contact from '@/views/contact/index.vue'
import Test from '@/views/product/test.vue'
import ProductItem from '@/views/product/item.vue'
const routes = [
  {
    path: '/',
    components: {
      header: Nav,
      default: Home,
      footer: Footer
    },
    meta: {
      "title": "忆伯科技"
    }
  },
  {
    path: '/home',
    components: {
      header: Nav,
      default: Home,
      footer: Footer
    },
    meta: {
      "title": "忆伯科技"
    }
  },
  {
    path: '/about',
    components: {
      header: Nav,
      default: About,
      footer: Footer
    }
  },
  {
    path: '/product',
    components: {
      header: Nav,
      default: Product,
      footer: Footer
    },

  },
  {
    path: '/productDetail',
    components: {
      header: Nav,
      default: ProductDetail,
      footer: Footer
    }
  },
  {
    path: '/news',
    components: {
      header: Nav,
      default: News,
      footer: Footer
    }
  },
  {
    path: '/newsDetail',
    components: {
      header: Nav,
      default: NewsDetail,
      footer: Footer
    }
  },
  {
    path: '/contact',
    components: {
      header: Nav,
      default: Contact,
      footer: Footer
    }
  },
  {
    path: '/test',
    components: {
      header: Nav,
      default: Test,
      footer: Footer
    }
  },
  {
    path: '/productItem',
    components: {
      header: Nav,
      default: ProductItem,
      footer: Footer
    }
  },
  //   {
  //     path: '/',
  //     component: Footer,
  //   },
]
const router = createRouter({
  history: createWebHashHistory(), routes
})

NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false });

router.beforeEach((to, from, next) => {
  NProgress.start() // 进度条开始
  next()
})

router.afterEach(() => {
  NProgress.done() // 进度条结束
})

export default router;
